.ant-form-item-control {
  position: relative;
  .ant-form-explain {
    position: absolute;
    bottom: -20px;
    font-size: 12px;
    color: #f5222d;
  }
}

.brd_num_btn_active {
  color: #fff;
  background: #ff1b61;
  border: 1px solid #ff1b61 !important;
}

.img_fixed {
  display: inline-block;
  object-fit: none;
}

.fp-slidesNav {
  display: none;
}
